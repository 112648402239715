const INSTANTADVICE = [
  // 智能问诊
  {
    // 列表
    path: '/instantAdvice/instantAdvice',
    name: 'instantAdvice',
    // 按需加载路由
    component: () => import('@/views/instantAdvice/instantAdvice'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default INSTANTADVICE
