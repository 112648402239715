const DEPARTMENT = [
  // 名医录
  {
    // 名医录列表
    path: '/Department/departmentdetail',
    name: 'departmentdetail',
    // 按需加载路由
    component: () => import('@/views/Department/Departmentdetail'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医生详情
    path: '/Department/doctorDetail',
    name: 'doctorDetail',
    // 按需加载路由
    component: () => import('@/views/Department/DoctorDetail'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 课程详情
    path: '/Department/courseDetail',
    name: 'courseDetail',
    // 按需加载路由
    component: () => import('@/views/Department/courseDetail'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default DEPARTMENT
