/*
 * @Author: duanyunlong
 * @since: 2020-07-22 22:05:22
 * @lastTime: 2020-07-24 20:54:41
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\main.js
 * @message: vue main文件
 */

import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
// 主要以iview组件库为主，饿了么按需引入（切记）
import { Carousel, CarouselItem, Scrollbar, Tree } from 'element-ui'
// 导入全局指令
import directive from './directive/index'
// 导入全局过滤器
import filters from './filters/index'
import SlideVerify from 'vue-monoplasty-slide-verify'
import Vant from 'vant'
import 'vant/lib/index.css'
import rem from './utils/rem.js'
import './assets/icon-font/iconfont.css'
import tim from './utils/tim'
import TIM from 'tim-js-sdk'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import wx from 'weixin-js-sdk'
import { VueJsonp } from 'vue-jsonp' // 网上很多博客引用不加{}，会报错
import TRTCCalling from 'trtc-calling-js'
import { createTrtcCalling } from './utils/trtc-calling'
import VueTouch from 'vue-touch'
import scroll from 'vue-seamless-scroll'
import Vue from 'vue'
import wechat from './utils/wechat'
// 剪贴板
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards)
Vue.prototype.$wx = wechat
Vue.use(VueTouch, { name: 'v-touch' })
Vue.prototype.$trtcCalling = createTrtcCalling()
Vue.prototype.$Calling = TRTCCalling
Vue.use(VueJsonp)
window.tim = tim
window.TIM = TIM
Vue.prototype.tim = tim
Vue.prototype.TIM = TIM
// 日期格式化
Vue.filter('formatDate', (value) => {
  if (!value) return ''
  let month = value.slice(5, 8)
  month = month.replace(/-/g, '月')
  const day = value.slice(8, 10)
  return month + day + '日'
})
// 单位转换
Vue.filter('Spectator', (value) => {
  if (value < 1000) {
    // eslint-disable-next-line no-self-assign
    value = value
  } else if (value < 10000) {
    value = (value / 1000).toFixed(1) + '千'
  } else {
    value = (value / 10000).toFixed(1) + '万'
  }
  return value
})

// 引入echarts
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts
Vue.prototype.$wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(scroll)
Vue.use(preview)
Vue.use(Viewer)
Vue.use(rem)
Vue.use(Vant)
// 安装iview
Vue.use(ViewUI)
// 使用全局指令
Vue.use(directive)
// 使用全局指令
Vue.use(filters)
// 安装饿了么走马灯等
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Scrollbar)
Vue.use(Tree)
// 安装SlideVerify组件
Vue.use(SlideVerify)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
