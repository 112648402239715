const ACADEMICLECTURES = [
  // 名医讲座
  {
    // 列表
    path: '/Academiclectures/lectureslist',
    name: 'lectureslist',
    // 按需加载路由
    component: () => import('@/views/Academiclectures/Lectureslist'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 课程详情
    path: '/Academiclectures/lecturesvideo',
    name: 'lecturesvideo',
    // 按需加载路由
    component: () => import('@/views/Academiclectures/Lecturesvideo'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default ACADEMICLECTURES
