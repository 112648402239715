const COURTYARDS = [
  // 名医录
  {
    // 名院录列表
    path: '/courtyards/courtyards',
    name: 'courtyards',
    // 按需加载路由
    component: () => import('@/views/courtyards/Courtyards'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 医院详情
    path: '/courtyards/hospitalDetails',
    name: 'hospitalDetails',
    // 按需加载路由
    component: () => import('@/views/courtyards/HospitalDetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default COURTYARDS
