/**
 * 接口地址(所有接口地址都在这里书写，进行统一的管理)
 * @type {{}}
 */
// import REQUEST_KEY from '../config/request'
const API_KEY = {
// 登陆相关接口地址
  login: {
    // 登陆
    login: '/login',
    // 登出
    loginOut: '/loginOut',
    loginPhone: '/loginPhone',
    getRsaPublicKey: '/getRsaPublicKey'
  },
  menu: {
    findMyMenu: '/systemMenu/listMyMenu',
    readMenuByPage: '/systemMenu/page',
    crudMenu: '/systemMenu/',
    // 获取菜单下拉
    readMenuSelect: '/systemMenu/listSelectMenuNames'
  },
  user: {
    // 专家广告位
    userDoctorAdvertising: '/userDoctorAdvertising/page',
    // 医院广告位
    userOrgAdvertising: '/OrgAdvertising/page',
    // 广告位医院详情
    getOrgDetail: '/OrgAdvertising/getOrgDetail',
    OrgAdvertising: '/OrgAdvertising/',
    administrativeOffice: '/administrativeOffice/page', // 查询科室
    hospital: '/hospital/page', // 查询所有医院
    getAdministrativeById: '/administrativeOffice/getAdministrativeByParentId', // 根据id查询所有大科室
    userDoctor: '/userDoctor/pageStat',
    userDoctorId: '/userDoctor/',
    gethospitalId: '/hospital/page2',
    uploadFile: '/orderRegisteredMail/uploadFile',
    uploadFileList: '/orderRegisteredMail/ListUploadFile',
    userDoctorPatients: '/userDoctorPatients/',
    userDoctorPatientspage: '/userDoctorPatients/page',
    userDoctorCommentpage: '/UserDoctorComment/page',
    userDoctorComment: '/UserDoctorComment/selectList',
    orderRegisteredMail: '/orderRegisteredMail/',
    getWXOAuth2Code: '/WechatPayInfo/getWXOAuth2Code',
    trainerTimetable: '/trainerTimetable/',
    gettrainerTimetable: '/trainerTimetable/',
    trainerTimetableinsertData: '/trainerTimetable/insertData',
    WxLogin: '/WxLogin/wx-oauth-code-return',
    adminGetRoamMsg: '/api/adminGetRoamMsg',
    getWebSdkApi: '/api/getWebSdkApi',
    getUserSig: '/api/getUserSig',
    adminMsgWithDraw: '/api/adminMsgWithDraw',
    userDoctorCertified: '/appUserDoctor/',
    listRootDictionary: '/systemDictionary/listRootDictionary',
    listDictionaryByRootCode: '/systemDictionary/listDictionaryByRootCode/',
    WxJsApi: '/WxChatPayInfo/WxJsApi',
    MagApi: '/Sms/MsgApi',
    orderRegisteredMailpageUserId: '/orderRegisteredMail/pageUserId',
    rechargeWechat: '/WxBalance/WxJsApi',
    appOrderRegisteredMailgetByTypePage: '/appOrderRegisteredMail/getByTypePage',
    byAccountUsergetBy: '/byAccountUser/getBy',
    // 修改充值余额订单支付信息
    orderRegisteredMailupdateByOrderIdye: '/orderRegisteredMail/updateByOrderIdye',
    // 修改订单支付信息
    orderRegisteredMailupdateByOrderId: '/orderRegisteredMail/updateByOrderId',
    // 充值记录查询
    OrderWater: '/OrderWater/getOrderWater',
    // 修改问诊订单状态
    OrderRegisteredMail: '/appOrderRegisteredMail/',
    userMedicalCompanion: '/AppMedicalCompanion/',
    // 余额支付
    balanceApiPay: '/WxChatPayInfo/balanceApiPay',
    // 根据用户ID查询医陪人员
    getByUserId: '/medicalCompanion/getByUserId',
    // 分页查询医陪订单
    medicalOrderPage: '/medicalOrder/page',
    // 修改医陪订单状态
    updateByOrderState: '/medicalOrder/updateByOrderState',
    // 添加评论
    UserDoctorCommentaddComment: '/UserDoctorComment/addComment',
    // 修改订单挂号状态
    updateByOrderStatus: '/appOrderRegisteredMail/updateByOrderStatus',
    // 订单退款
    WxRefundApi: '/WxChatPayInfo/WxRefundApi',
    // 新增充值记录
    addOrderWater: '/OrderWater/addOrderWater',
    // 分页查询客户中心标题
    pageDateServiceCentre: '/serviceCenter/pageDateServiceCentre',
    // 修改订单方式
    orderRegisteredMailupdateData: '/orderRegisteredMail/updateData',
    // 判断该患者该时间是否存预约
    trainerTimetablegetByTimeJudge: '/trainerTimetable/getByTimeJudge',
    // 取消订单信息
    orderRegisteredMailcancelOrder: '/orderRegisteredMail/cancelOrder',
    // 新增问诊聊天记录
    chattingRecordsaddChatting: '/chattingRecords/addChatting',
    // 微信自定义分享
    WxChatSharegetUrl: '/WxChatShare/getUrl',
    // 根据用户ID查询相关信息
    AccountUserInfogetUserInfo: '/AccountUserInfo/getUserInfo',
    // 修改用户拓展信息
    AccountUserInfo: '/AccountUserInfo/',
    // 分页查询医生视频价格
    UserDoctorPricepageVideoPrice: '/UserDoctorPrice/pageVideoPrice',
    // 查询该用户所在位置
    getAwaitCount: '/api/getAwaitCount',
    // 查询当天该医生已接单人数
    getQueueCount: '/api/getQueueCount'
  },
  info: {
    doctorInfo: '/appRecommendationDoctor/',
    addInfo: '/appUsersCollectInformation/'
  },
  doctor: {
    appOrderRegisteredMail: '/appOrderRegisteredMail/page',
    appUserDoctor: '/appUserDoctor/pageStat',
    // 获取挂号记录
    appOrderGetByTypePage: '/appOrderRegisteredMail/getByTypePage',
    // 修改挂号记录状态
    appOrderupdateByOrderStatus: '/appOrderRegisteredMail/updateByOrderStatus',
    // 名医录查看自己的用户收集
    getUserByUserId: '/appUsersCollectInformation/getUserByUserId',
    // 根据用户id查询详情
    getDataByUserId: '/appDoctorDirectories/getDataByUserId',
    // 云点播视频上传(视频id)
    uploadAlyiVideo: '/orderRegisteredMail/uploadAlyiVideo',
    // 修改名医录
    appDoctorDirectories: '/appDoctorDirectories/'
  },
  // 医陪
  accompany: {
    page: '/medicalOrder/page', // 分页
    one: '/medicalOrder/', // 新增
    updatePayState: '/medicalOrder/updateBypPaymentType', // 支付状态
    orderState: '/medicalOrder/updateByOrderState', // 医陪状态
    // 评论
    addComment: '/medicalEvaluate/', // 新增评论
    // 医患
    getUserDoctorPatientsOne: '/userDoctorPatients/',
    // 医陪人员
    getPerson: '/medicalCompanion/',
    getPersonByUserId: '/medicalCompanion/getByUserId', // 用户id查医陪
    // 用户id查用户
    getUser: '/accountUser/',
    // 上传
    upload: '/orderRegisteredMail/uploadFile',
    // 医陪子项
    addMedicalSubitem: '/medicalSubitem/', // 新增，修改
    // 医陪人员推荐分页，id查询
    pageDataByRecommend: '/AppMedicalCompanion/pageDataByRecommend',
    // 好差评
    pageDateByEvaluate: '/medicalEvaluate/pageDateByEvaluate'
  },
  // 支付相关
  payRelated: {
    weChat: '/WxChatPayInfo/WxJsApi',
    balance: '/WxChatPayInfo/balanceApiPay',
    // vx退款
    refund: '/WxChatPayInfo/WxRefundApi'
  },
  // 各下拉
  other: {
    // 医患人员
    medicalPersonnel: '/userDoctorPatients/page',
    // 医院下拉
    hospital: '/hospital/page',
    // 科室级联(传医院ID)
    departments: '/administrativeOffice/getByHospitalAccountTop',
    // 就诊描述(数据字典)
    describe: '/systemDictionary/mapRoleCodeListDictionaryInRootCode'
  },
  mcn: '/AppConsultingDoctorMcn/',
  mcnPage: '/AppConsultingDoctorMcn/page',
  // 圈子相关
  circle: {
    insert: '/community/circle/insertH5',
    page: '/community/circle/getByUserId',
    pageFirst: '/community/circle/page',
    one: '/community/circle/',
    joinCircle: '/community/relation/',
    carousel: '/community/circle/selectByTally',
    // 文章
    article: {
      page: '/community/article/selectByPage',
      doctorPage: '/community/article/page'
    },
    // id查询文章详情
    inquire: '/community/article/',
    // 文章详情页评论查询
    selectByPage: '/community/articleComment/selectByPage',
    selectByPageSecond: '/community/articleComment/selectByParentId',
    // 新增文章评论
    articleComment: '/community/articleComment/',
    // 新增，修改文章点赞转发
    articleFabulous: '/community/articleFabulous/',
    // 问答
    questions: {
      page: '/community/question/selectPage',
      one: '/community/question/',
      commentPage: '/community/questionReply/selectByPage',
      commentPageSecond: '/community/questionReply/selectByParentId',
      addComment: '/community/questionReply/'
    },
    // 点赞转发
    fabulous: {
      add: '/community/questionFabulous/'
    },
    // 标签
    label: {
      page: '/community/tally/page',
      one: '/community/tally/',
      selectCircles: '/community/circle/selectByRelationTally',
      selectQuestions: '/community/question/getByTallyId'
    }
  },
  // 视频模块
  video: {
    // 社区分页视频
    VideoPage: '/community/video/page',
    // 获取视频凭证
    getPlayAuth: '/orderRegisteredMail/getPlayAuth/',
    // 获取视频凭证
    getPlayUrl: '/orderRegisteredMail/getPlayUrl/',
    // 点赞
    like: '/community/like/like',
    // 点赞分页
    likePage: '/community/like/page',
    // 关注
    follow: '/community/attention/follow',
    // 评论
    commentpage: '/community/comment/page',
    // 新增评论
    addComment: '/community/comment/',
    // 通过id查询用户详细信息
    userPage: '/community/video/userAndUserInfoPage',
    // 新增回复
    addReply: '/community/reply/',
    // 分页查询回复
    replyPage: '/community/reply/page',
    // 根据视频id查询视频信息
    videogetById: '/community/video/getById/',
    // 分享
    share: '/community/share/',
    // 用户端 我的主页(有统计数)
    userAndCountPage: '/community/video/userAndCountPage',
    // 我的收藏
    collect: '/community/love/getDataByUserId',
    // 新增聊天记录
    addepic: '/community/epic/',
    // 查询聊天记录
    epicpage: '/community/epic/page',
    // 新增私信组
    epicgroup: '/community/epic/group/',
    // 查询私信组
    epicgrouppage: '/community/epic/group/page'
  },
  // 直播模块
  live: {
    // 直播分页查询
    livegetPage: '/CommunityLiveStreaming/getPage',
    // 通过房间号查询直播详情
    livegetDetails: '/CommunityLiveStreaming/getDetails/',
    // 创建直播
    CommunityLiveStreaming: '/CommunityLiveStreaming/',
    // 结束直播
    endLive: '/CommunityLiveStreaming/endLive',
    // 人气点赞
    popularity: '/CommunityLiveStreaming/popularity/',
    // 根据房间号查询直播人气,在线观看人数
    getLiveAndPopularity: '/CommunityLiveStreaming/getLiveAndPopularity/',
    // 获取用户头像
    getUserPortrait: '/CommunityLiveStreaming/getUserPortrait/',
    // 退出直播
    signOut: '/CommunityLiveStreaming/signOut/',
    // 查询直播礼物列表
    Giftpage: '/communityLiveStreamingGift/page',
    // 直播打赏
    Giftreward: '/communityLiveStreamingGift/reward/',
    // 获取详情
    getStreamingGift: '/communityLiveStreamingGift/'
  },
  // 我的
  mine: {
    attentionAndFans: '/community/attention/getData',
    cancelAttention: '/community/attention/follow',
    countInfo: '/community/video/userAndCount'
  },
  // 名医录，名院录
  department: {
    famousDirectoriesPage: '/appDoctorDirectories/page',
    famousHospitalRecordPage: '/famousHospitalRecord/page',
    appDoctorDirectoriesShow: '/appDoctorDirectories/',
    appdoctorRecommend: '/appDoctorDirectories/doctorRecommend',
    famousHospitalRecord: '/famousHospitalRecord/',
    famousHospitalDepartmentPage: '/famousHospitalDepartment/page',
    famousHospitalLeaderpage: '/famousHospitalLeader/page',
    doctorVideo: '/community/doctorVideo/page',
    getDoctorVideo: '/community/doctorVideo/',
    addUser: '/appUsersCollectInformation/',
    coursegetByDoctorId: '/doctorDirectoriesCourse/getByDoctorId/',
    doctorGetByCourseId: '/doctorDirectoriesChapter/getByCourseId/',
    doctorDirectoriesContent: '/doctorDirectoriesContent/getByChapterId/',
    doctorContent: '/doctorDirectoriesContent/',
    shareWxApp: '/WxChatShare/getUrlYi',
    WxChatShare: '/WxChatShare/toRedirect'
  },
  instantAdvice: {
    chatConsultation: '/api/chat/consultation'
  }
}
export default API_KEY
